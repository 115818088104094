import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import './App.css';

// Define paths to your CSV files with corresponding names
const csvFiles = [
  { path: '/data/Allergens.csv', name: 'Allergens Dataset' },
  { path: '/data/AminoAcids.csv', name: 'Amino Acids Dataset' },
  { path: '/data/AntiNutrientsInFood.csv', name: 'Anti-Nutrients in Food Dataset' },
  { path: '/data/Antioxidants.csv', name: 'Antioxidants Dataset' },
  { path: '/data/BioavailabilityOfNutrients.csv', name: 'Bioavailability of Nutrients Dataset' },
  { path: '/data/DietaryFiberType.csv', name: 'Dietary Fiber Type Dataset' },
  { path: '/data/DietaryGuidelinesByAgeGroup.csv', name: 'Dietary Guidelines by Age Group Dataset' },
  { path: '/data/FoodAdditivesAndPreservatives.csv', name: 'Food Additives and Preservatives Dataset' },
  { path: '/data/FoodSensitivities.csv', name: 'Food Sensitivities Dataset' },
  { path: '/data/GutHealth.csv', name: 'Gut Health Dataset' },
  { path: '/data/Hormones.csv', name: 'Hormones Dataset' },
  { path: '/data/InteractionsBetweenMedicationsAndNutrients.csv', name: 'Interactions Between Medications and Nutrients Dataset' },
  { path: '/data/Minerals.csv', name: 'Minerals Dataset' },
  { path: '/data/NutritionalNeedsForSpecialPopulations.csv', name: 'Nutritional Needs for Special Populations Dataset' },
  { path: '/data/Phytochemicals.csv', name: 'Phytochemicals Dataset' },
  { path: '/data/Probiotics.csv', name: 'Probiotics Dataset' },
  { path: '/data/ToxinsInFood.csv', name: 'Toxins in Food Dataset' },
  { path: '/data/Vitamins.csv', name: 'Vitamins Dataset' }
];

const App = () => {
  const [symptoms, setSymptoms] = useState('');
  const [results, setResults] = useState([]);
  const [parsedData, setParsedData] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState('');
  const [datasetData, setDatasetData] = useState([]);

  // Function to handle CSV parsing
  const parseCSV = (file, name, callback) => {
    Papa.parse(file.path, {
      download: true,
      header: true,
      complete: function (results) {
        callback(results.data, name);
      }
    });
  };

  // Load all datasets when the component mounts
  useEffect(() => {
    const allData = [];
    const promises = csvFiles.map(file =>
      new Promise(resolve => {
        parseCSV(file, file.name, (data, source) => {
          data.forEach(item => item.source = source); // Add source field
          allData.push(...data);
          resolve();
        });
      })
    );

    Promise.all(promises).then(() => {
      setParsedData(allData);
    });
  }, []);

  // Function to analyze symptoms based on dataset
  const analyzeSymptoms = (symptomInput) => {
    if (parsedData.length === 0) return [];

    return parsedData.filter(item => {
      const deficiencySymptoms = item['Deficiency Symptoms'] ? item['Deficiency Symptoms'].toLowerCase() : '';
      const excessSymptoms = item['Excess Symptoms'] ? item['Excess Symptoms'].toLowerCase() : '';
      return symptomInput.some(symptom => 
        deficiencySymptoms.includes(symptom.toLowerCase()) ||
        excessSymptoms.includes(symptom.toLowerCase())
      );
    }).filter(item => {
      // Exclude results where both fields are 'N/A' or 'Unknown'
      return (item['Deficiency Symptoms'] && item['Deficiency Symptoms'] !== 'N/A') ||
             (item['Excess Symptoms'] && item['Excess Symptoms'] !== 'N/A') ||
             (item.Toxin && item.Toxin !== 'Unknown') ||
             (item.Vitamin && item.Vitamin !== 'Unknown');
    });
  };

  // Function to handle the form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const symptomArray = symptoms.split(',').map(symptom => symptom.trim());
    const results = analyzeSymptoms(symptomArray);
    setResults(results);
    setSelectedDataset(''); // Clear dataset view
  };

  // Function to handle dataset button click
  const handleDatasetClick = (datasetName) => {
    setSelectedDataset(datasetName);
    const filteredData = parsedData.filter(item => item.source === datasetName);
    setDatasetData(filteredData);
    setResults([]); // Clear symptom results view
  };

  return (
    <div className="container">
      <h1>Symptom Checker</h1>

      {/* Symptom Input Form */}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter symptoms, separated by commas"
          value={symptoms}
          onChange={(e) => setSymptoms(e.target.value)}
        />
        <button type="submit">Analyze Symptoms</button>
      </form>

      {/* Dataset Buttons */}
      <div className="dataset-buttons">
        {csvFiles.map(file => (
          <button
            key={file.name}
            onClick={() => handleDatasetClick(file.name)}
            className={selectedDataset === file.name ? 'active' : ''}
          >
            {file.name}
          </button>
        ))}
      </div>

      {/* Dataset Table */}
      {selectedDataset && (
        <div className="dataset-table">
          <h2>{selectedDataset}</h2>
          <table>
            <thead>
              <tr>
                {Object.keys(datasetData[0] || {}).map((key, index) => (
                  <th key={index}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {datasetData.map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, idx) => (
                    <td key={idx}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Results Display */}
      {results.length > 0 && !selectedDataset && (
        <div>
          <h2>Possible Deficiencies, Excesses, or Toxin Effects:</h2>
          <ul>
            {results.map((result, index) => (
              <li key={index}>
                <strong>Substance:</strong> {result.Toxin || result.Vitamin || 'Unknown'}
                <br />
                <strong>Deficiency Symptoms:</strong> {result['Deficiency Symptoms'] || 'N/A'}
                <br />
                <strong>Excess Symptoms:</strong> {result['Excess Symptoms'] || 'N/A'}
                <br />
                <strong>Source:</strong> {result.source || 'N/A'}
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="shape"></div>
      <div className="shape"></div>
    </div>
  );
};

export default App;
